import React from 'react';
import swal from "sweetalert";
//import { useNavigate } from "react-router-dom";


import {
    formatError,
    login,
    forget,
    Resetforget,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
} from '../../services/AuthService';


export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';



export function signupAction(name,email, password, cpassword,navigate) {
	
    return (dispatch) => {
        signUp(name,email, password,cpassword)
        .then((response) => {
            saveTokenInLocalStorage(response.data);
            runLogoutTimer(
                dispatch,
                response.data.expiresIn * 1000,
                //history,
            );
            dispatch(confirmedSignupAction(response.data));
           // navigate('/dashboard');
            window.location.href = "/dashboard";
			//history.push('/dashboard');
        })
        .catch((error) => {

            swal("Oops",error.response.data.message, "error",{ button: "Try Again!",});
           // const errorMessage = formatError(error.response.data);
            //dispatch(signupFailedAction(errorMessage));

        });
    };
}

export function Logout(navigate) {
    
	//localStorage.removeItem('userDetails');
   // navigate('/login');
	//history.push('/login');
    
	return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(email, password, navigate) {
    return (dispatch) => {
         login(email, password)
            .then((response) => { 
                saveTokenInLocalStorage(response.data);
                runLogoutTimer(
                    dispatch,
                    response.data.expiresIn * 1000,
                    navigate,
                );
               dispatch(loginConfirmedAction(response.data));
			   //console.log('kk------1');
			   //console.log(kk);
			   //console.log(response.data);
			   //console.log('kk------2');
			   //return response.data;
				//return 'success';
				//history.push('/dashboard');                
				navigate('/dashboard');                
            })
            .catch((error) => {

                swal("Oops",error.response.data.message, "error",{ button: "Try Again!",});
                /*    
				//console.log('error');
				//console.log(error);
                const errorMessage = formatError(error.response.data);
                dispatch(loginFailedAction(errorMessage));
                */ 
            });
    };
}


export function forgetAction(email, navigate) {
    return (dispatch) => {
         forget(email)
            .then((response) => {
               
                swal("Success","Reset Link Send To Register Email ID", "success",{ button: "Ok",});
  
                          
            })
            .catch((error) => {

                swal("Oops",error.response.data.message, "error",{ button: "Try Again!",});
                
            });
    };
}

export function ResetPasswordAction(password, cpassword, token,  navigate) {
    return (dispatch) => {
         Resetforget(password, cpassword, token)
            .then((response) => {
               
                swal("Success",response.data.message, "success",{ button: "Ok",});
  
                          
            })
            .catch((error) => {

                swal("Oops",error.response.data.message, "error",{ button: "Try Again!",});
                
            });
    };
}
export function EmailVerificationAction(email_verification_token,  navigate) {

    let password = "";
    let cpassword = "";
    
    return (dispatch) => {
         Resetforget(password, cpassword, email_verification_token)
            .then((response) => {
               "Hi Testing";
                //swal("Success",response.data.message, "success",{ button: "Ok",});
  
                          
            })
            .catch((error) => {
                   "Hi Error"; 
                //swal("Oops",error.response.data.message, "error",{ button: "Try Again!",});
                
            });
    };
}



export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
